.logo-container {
  position: fixed;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
}

.logo {
  pointer-events: none;
  max-height: 14vmin;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  min-width: 5%;
  min-height: 5%;
}

.landing-menu {
  color: #FFFFFF;
  position: fixed;
  left: 15px;
  bottom: 20px;
  font-size: 1.6rem;
  font-weight: 400;
  text-align: left;
}

.landing-menu a, .landing-menu a:hover {
  color: #FFFFFF;
  margin: 0 5px;
  text-decoration: none;
}
